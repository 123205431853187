import * as Types from '../../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/main/frontend/graphql/README.md for more information.
* Last Updated: Wed Apr 02 2025 15:11:13 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type OnboardingActivitiesQueryVariables = Types.Exact<{
  featureId: Types.Scalars['String']['input'];
}>;


export type OnboardingActivitiesQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, onboardingActivities?: { __typename?: 'OnboardingActivityConnection', nodes?: Array<{ __typename?: 'OnboardingActivity', id: string, action: Types.OnboardingActivityAction } | null | undefined> | null | undefined } | null | undefined } | null | undefined };


export const OnboardingActivitiesDocument = gql`
    query OnboardingActivities($featureId: String!) {
  currentUser {
    id
    onboardingActivities(featureId: $featureId) {
      nodes {
        id
        action
      }
    }
  }
}
    `;

/**
 * __useOnboardingActivitiesQuery__
 *
 * To run a query within a React component, call `useOnboardingActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingActivitiesQuery({
 *   variables: {
 *      featureId: // value for 'featureId'
 *   },
 * });
 */
export function useOnboardingActivitiesQuery(baseOptions: Apollo.QueryHookOptions<OnboardingActivitiesQuery, OnboardingActivitiesQueryVariables> & ({ variables: OnboardingActivitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingActivitiesQuery, OnboardingActivitiesQueryVariables>(OnboardingActivitiesDocument, options);
      }
export function useOnboardingActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingActivitiesQuery, OnboardingActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingActivitiesQuery, OnboardingActivitiesQueryVariables>(OnboardingActivitiesDocument, options);
        }
export function useOnboardingActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OnboardingActivitiesQuery, OnboardingActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingActivitiesQuery, OnboardingActivitiesQueryVariables>(OnboardingActivitiesDocument, options);
        }
export type OnboardingActivitiesQueryHookResult = ReturnType<typeof useOnboardingActivitiesQuery>;
export type OnboardingActivitiesLazyQueryHookResult = ReturnType<typeof useOnboardingActivitiesLazyQuery>;
export type OnboardingActivitiesSuspenseQueryHookResult = ReturnType<typeof useOnboardingActivitiesSuspenseQuery>;
export type OnboardingActivitiesQueryResult = Apollo.QueryResult<OnboardingActivitiesQuery, OnboardingActivitiesQueryVariables>;